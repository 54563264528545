.violation-card-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    z-index: 5;
}

.violation-card {
    width: 100%;
    height: 45vh;
    max-height: 75vh;
    background-color: var(--background);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: var(--card-shadow);
}

.violation-card.open {
    transform: translateY(0);
    min-height: 45vh;
}

.violation-header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: var(--background);
    box-shadow: var(--car-header-shadow);
    color: var(--text);
}

.content {
    color: #323232;
    padding: 10px;
}

.card-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
    font-size: 20px;
    text-align: left;
    color: var(--text);
}

.close-button {
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: var(--light-grey);
    color: var(--icon-invert);
    border: none;
    font-size: 18px;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: background-color 0.2s ease;
    box-shadow: var(--card-header-shadow)
}

.close-button:hover {
    background-color: var(--black);
}

.icon {
    display:block;
    margin-right: 10px;
    color: black;
    margin-left: 30px;
    margin-top: 10px;
}

.header-data {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px;
    margin-left: 0px;
    font-weight: 300;
}

.icon img {
    width: 150px;
    height: auto;
    vertical-align: middle;
    display: inline-block;
    margin-top: 5px;
    margin-left: -20px;
}

.title-card {
    display: flex;
    align-items: center;
    width: 1000px;
    color: #323232;
    padding: 10px;
    border: 0px solid transparent;
    border-radius: 20px;
    box-shadow: var(--card-header-shadow);
    background-color: rgb(255, 255, 255);
    margin: 5px;
}

.title-card-header {
    font-size: 25px;
    font-weight: 700;
    margin-right: 0px;
    margin-top: 10px;
}

.title-info-description {
    line-height: 1.5;
}

.time-parked-container {
    display: flex;
    flex-direction: column;
}

.time-parked-container > div {
    display: flex;
    margin-right: 10px;
}

.time-parked {
    padding-left: 4px;
}

.button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 0px;
    margin-bottom: 10px;
    border-color: transparent;
}

.citation-button {
    color: #323232;
    background-color: rgb(233, 233, 233);
    font-size: 18px;
    cursor: pointer;
    z-index: 100;
    box-shadow: var(--card-header-shadow);
    cursor: pointer;
}

.card-image {
    filter: var(--image-icon)
}

.citation-button.darkened {
    opacity: 0.7;
    pointer-events: none;
    cursor: not-allowed;
}

.citation-button.checked {
    opacity: 0.7;
    pointer-events: none;
    cursor: not-allowed;
    background-color: #aad7a4;
}