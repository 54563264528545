.map-page {
    display: flex;
    flex-direction: column;
    touch-action: pan-x pan-y;
    overflow: hidden;
}

.toggle-button {
    position: absolute;
    margin-top: 10px;
    right: 20px;
    max-width: 50px;
    padding: 5px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    z-index: 2;
}

.toggle-button.light {
    background-color: #333;
    color: #f0f0f0;
}

.toggle-button.dark {
    background-color: #f0f0f0;
    color: #333;
}

.toggle-button:focus {
    outline: white;
}

.toggle-button:hover {
    background-color: lightgrey;
    color: #323232;
}

.refresh-button {
    position: absolute;
    margin-top: 10px;
    right: 20px;
    padding: 10px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

.marker {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: rgba(0, 255, 255, 0);
    cursor: pointer;
    transition: transform 0.3s;
    z-index: 100;
}

.marketText {
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: rgba(0, 255, 255, 0);
    cursor: pointer;
    transition: transform 0.3s;
    z-index: 100;
}

.mapboxgl-ctrl-geolocate {
    font-size: 20px;
    /* Increase the font size */
    width: 50px;
    /* Increase the width */
    height: 50px;
    /* Increase the height */
    padding: 10px;
    /* Add padding */
}

.mapboxgl-ctrl-icon {
    width: 100%;
    /* Ensure the icon scales with the button */
    height: 100%;
    /* Ensure the icon scales with the button */
}