:root {
    --background: white;
    --yellow: #ffe374;
    --light-yellow: #fff7d9;
    --grey: #323232;
    --light-grey: #f1f1f1;
    --medium-grey: #6C6C6C;
    --white: white;
    --black: black;
    --li-gradient: #f9e7a0;
    --icon-invert: #323232;
    --text: #323232;
    --list-item-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    --header-color: rgba(255, 243, 162, 0.3);
    /* --header-color: rgba(255, 255, 255, 0.3); */
    --image-icon: invert(0%);
    --card-shadow: 0px -2px 4px rgba(0, 0, 0, 0.2);
    --car-header-shadow: 4px 4px 6px rgba(0, 0, 0, 0.2);
    --lot-background: #F1F1F1;
    --loader-container: #f1f1f1;
}

/* rgba(255, 255, 255, 0.3); */
/* linear-gradient(to right, var(--li-gradient), var(--background)); */

@media (prefers-color-scheme: dark) {
    :root {
        --background: #323232;
        --yellow: #ffe374;
        --grey: #323232;
        --light-grey: #6C6C6C;
        --white: rgb(221, 221, 221);
        --black: rgb(221, 221, 221);
        --li-gradient: #323232;
        --icon-invert: rgb(221, 221, 221);
        --text: rgb(221, 221, 221);
        --list-item-shadow: 0px 0px 5px rgba(255, 255, 255, 0.15);
        --header-color: rgba(120, 120, 120, 0.3);
        --image-icon: invert(100%);
        --card-shadow: 0px -2px 4px rgba(255, 255, 255, 0.9);
        --car-header-shadow: 4px 4px 6px rgba(255, 255, 255, 0.9);
        --lot-background: #4d4d4d;
        --loader-container: #414141;
    }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

@layer utilities {
    .fcc {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .frc {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
    }

    .frb {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }

    .gray-gradient {
        background: linear-gradient(to right, #202225, #36393F);
    }

    .light-gray-gradient {
        background: linear-gradient(to right, #F2F3F5, #EBEDEF);
    }

    .yellow-gradient {
        background: linear-gradient(to right, #FFE374, #FFF59D);
    }

    .rubik-light {
        @apply font-rubik font-light;
    }

    .rubik-normal {
        @apply font-rubik font-normal;
    }

    .rubik-medium {
        @apply font-rubik font-medium;
    }

    .rubik-bold {
        @apply font-rubik font-bold;
    }

    .rubik-extrabold {
        @apply font-rubik font-extrabold;
    }
}

.App {
    font-family: 'SF-Pro-Text-Regular', sans-serif;
    src: local('SF-Pro-Text-Regular'), url("./assets/fonts/SF-Pro-Text-Regular.otf") format('otf');
    text-align: center;
    background: var(--background);
    color: #f7f7f7;
}

body {
    font-family: 'SF-Pro-Text-Regular', sans-serif;
    src: local('SF-Pro-Text-Regular'), url("./assets/fonts/SF-Pro-Text-Regular.otf") format('otf');
    background-color: var(--background);
    margin-bottom: 200px;
}

body>iframe {
    display: none;
}

h1 {
    font-family: 'SF-Pro-Text-Regular', sans-serif;
    src: local('SF-Pro-Text-Regular'), url("./assets/fonts/SF-Pro-Text-Regular.otf") format('otf');
    color: #323232;
    font-size: 28px;
    text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
}

h2 {
    font-family: 'SF-Pro-Text-Regular', sans-serif;
    src: local('SF-Pro-Text-Regular'), url("./assets/fonts/SF-Pro-Text-Regular.otf") format('otf');
    color: #323232;
}

h3 {
    font-family: 'SF-Pro-Text-Regular', sans-serif;
    src: local('SF-Pro-Text-Regular'), url("./assets/fonts/SF-Pro-Text-Regular.otf") format('otf');
    font-size: 18px;
    color: #323232;
    font-weight: 300;
}

li {
    font-family: 'SF-Pro-Text-Regular', sans-serif;
    src: local('SF-Pro-Text-Regular'), url("./assets/fonts/SF-Pro-Text-Regular.otf") format('otf');
    font-size: 14px;
    text-align: left;
    padding-bottom: 10px;
}

.title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}

.titleText {
    color: black;
    font-size: 40px;
}

.testingStyle {
    color: blue;
    font-size: 50px;
    font-weight: 700;
}

.headerLogo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    margin: 20px;
}

/* Filter Button */
.sort-button {
    background: #f0ecdb;
    color: #323232;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    margin-right: 10px;
    display: flex;
    align-items: center;
    font-size: 16px;
    padding: 8px 16px;
}

.sort-button:hover {
    background-color: #f7f7f7;
}

.sort-button-icon {
    font-size: 20px;
    margin-right: 5px;
}

.sort-dropdown {
    position: absolute;
    top: 40px;
    right: 20px;
    background: var(--background);
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 2;
    padding: 8px;
    display: flex;
    flex-direction: column;
}

.sort-dropdown select {
    background: var(--background);
    border: none;
    font-size: 15px;
    padding: 8px;
    border-radius: 4px;
    outline: none;
    appearance: none;
    cursor: pointer;
}

.ticket-details {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    transition: transform 300ms ease-in-out, opacity 300ms ease-in-out 500ms;
    /* Ensure the div starts with a hidden state */
    transform: scale(0);
    opacity: 0;
    margin-top: 0;
}

.ticket-details.default {
    transform: scale(0);
    opacity: 0;
    transition: transform 0ms ease-in-out, opacity 0ms ease-in-out 0ms;
}

.ticket-details.clicked {
    transform: scale(1);
    opacity: 1;
    margin-top: 1rem;
    /* Adjust as needed */
    transition: transform 300ms ease-in-out, opacity 300ms ease-in-out 500ms;
}

.description {
    font-size: 12px;
    margin-bottom: 10px;
    margin-right: 20px;
    margin-left: 20px;
}

.cta-button {
    padding: 10px 20px;
    background-color: var(--yellow);
    font-weight: 700;
    color: black;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    margin-top: 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    font-size: 14px;
}

.cta-button:hover {
    background-color: var(--light-grey);
}

.dropShadow {
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
}

.content {
    text-align: center;
    background-color: var(--background);
    color: #323232;
}

/* Loading */
.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}

.loading-spinner {
    width: 40px;
    height: 40px;
    border: 4px solid var(--light-grey);
    border-top: 4px solid var(--yellow);
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.contact-us-container {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    justify-content: center;
    align-items: center;
    padding: 30px;
    overflow-y: scroll;
    overflow: scroll;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}